#message {
  .personal-data {
    span {
      border-bottom: 2px lightgreen solid;
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2020 Werbeagentur Christian Aichner
 */
