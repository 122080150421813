.flyout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

// Custom colors
.sithcult-dark {
  background-color: $agencyDark !important;
  &.darken-1 {
    background-color: darken($agencyDark, 5%) !important;
  }
  &.darken-2 {
    background-color: darken($agencyDark, 10%) !important;
  }
  &.darken-3 {
    background-color: darken($agencyDark, 15%) !important;
  }
  &.lighten-1 {
    background-color: lighten($agencyDark, 10%) !important;
  }
  &.lighten-2 {
    background-color: lighten($agencyDark, 20%) !important;
  }
  &.lighten-3 {
    background-color: lighten($agencyDark, 30%) !important;
  }
}
.sithcult-red {
  background-color: $agencyRed !important;
  &.darken-1 {
    background-color: darken($agencyRed, 10%) !important;
  }
  &.darken-2 {
    background-color: darken($agencyRed, 20%) !important;
  }
  &.darken-3 {
    background-color: darken($agencyRed, 30%) !important;
  }
  &.lighten-1 {
    background-color: lighten($agencyRed, 10%) !important;
  }
  &.lighten-2 {
    background-color: lighten($agencyRed, 20%) !important;
  }
  &.lighten-3 {
    background-color: lighten($agencyRed, 30%) !important;
  }
}

// Custom text
.text-red {
  color: $agencyRed !important;
}
.text-gold {
  color: gold !important;
}
.text-white {
  color: white !important;
}

body {
  background-color: #f2f2f2 !important;
}

// Custom buttons
.underlined {
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  color: gold;
  padding-bottom: 0.15em;
  box-sizing: border-box;
  box-shadow: inset 0 -0.2em 0 gold;
  transition: 0.2s;
  &:hover {
    color: #222;
    box-shadow: inset 0 -2em 0 gold;
    transition: all 0.45s cubic-bezier(0.86, 0, 0.07, 1);
  }
}

.clickable {
  cursor: pointer;
}

.btn-modern {
  overflow: visible !important;
  position: relative;
  background: none;
  color: gold;
  text-transform: uppercase;
  font-size: 0.9em;
  text-decoration: none;
  border: 0.2em solid gold;
  padding: 0.5em 1em;
  box-shadow: none;
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 10%;
    background: #222;
    height: 0.3em;
    right: 20%;
    top: -0.21em;
    transform: skewX(-45deg);
    -webkit-transition: all 0.45s cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 0.45s cubic-bezier(0.86, 0, 0.07, 1);
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 10%;
    background: #222;
    height: 0.3em;
    left: 20%;
    bottom: -0.25em;
    transform: skewX(45deg);
    -webkit-transition: all 0.45 cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 0.45s cubic-bezier(0.86, 0, 0.07, 1);
  }
  &:hover {
    color: gold;
    &::before {
      right: 80%;
    }
    &::after {
      left: 80%;
    }
  }
}

.btn {
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
  &:focus {
    box-shadow: none;
  }
  &:active {
    box-shadow: none;
  }
  &.btn-agency-red {
    background: $agencyRed;
    color: white;
    &:hover {
      background: lighten($agencyRed, 10%);
    }
  }
  &.btn-primary {
    background: #2a3c4a !important;
    color: white;
    &:hover {
      background: lighten(#2a3c4a, 6%) !important;
    }
  }
}

// Custom scrollbar
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.btn {
  i {
    margin-right: 0.3rem;
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2020 Christian Aichner
 */
